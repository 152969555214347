import { get, post, request, download, del, put } from '@/service/index.js';

/* 凭证设置查询 */
export const configGet = (data) => {
  return get('/vou/config/get', data).then((res) => res);
};

/* 凭证设置新增更新 */
export const configSave = (data) => {
  return post('/vou/config/save', data).then((res) => res);
};

/* 科目列表 */
export const getList = (data) => {
  return post('/voucher/accounts/page', data).then((res) => res);
};

// 凭证列表
export const voucherList = (data) => {
  return get('/vou/voucher/list', data).then((res) => res);
};

// 同步推送凭证
export const voucherPush = (data) => {
  return post('/vou/voucher/push', data).then((res) => res);
};

// 同步刷新凭证
export const voucherSync = (data) => {
  return post('/vou/voucher/sync', data).then((res) => res);
};

// 删除凭证
export const voucherDel = (id) => {
  return del(`/vou/voucher/${id}`).then((res) => res);
};

// 批量删除凭证
export const voucherBatchDel = (data) => {
  return del('/vou/voucher/batch', data).then((res) => res);
};

// 凭证模板列表
export const voucherTmpl = (data) => {
  return get('/voucher/tmpl/list', data).then((res) => res);
};

// 凭证模板全部列表
export const voucherTmplList = (data) => {
  return get('/voucher/tmpl/all', data).then((res) => res);
};

// 凭证模板修改
export const voucherTmplItemUpdate = (data) => {
  return post('/voucher/tmpl/voucherTmplItemUpdate', data).then((res) => res);
};

// 凭证模板添加
export const voucherAdd = (data) => {
  return post('/voucher/tmpl/voucherTmplAdd', data).then((res) => res);
};

// 会计科目
export const getVoucherAccounts = (data) => {
  return post('/voucher/accounts/getVoucherAccounts', data).then((res) => res);
};

// 项目管理树形结构
export const getProjectTree = (data) => {
  return get('/project/tree', data).then((res) => res);
};

// 项目管理列表
export const projectList = (data) => {
  return get('/project/query', data).then((res) => res);
};

// 项目管理列表(无分页)
export const projectAll = (data) => {
  return get('/project/all', data).then((res) => res);
};

// 项目管理导入
export const projectImport = (data) => {
  return post('/project/import', data).then((res) => res);
};

// 项目管理推送
export const projectPush = (data) => {
  return post('/project/push', data).then((res) => res);
};

// 项目管理添加
export const projectAdd = (data) => {
  return post('/project/add', data).then((res) => res);
};

// 项目管理编辑
export const projectEdit = (data) => {
  return put('/project/update', data).then((res) => res);
};

// ID查询
export const selectProject = (id) => {
  return get(`/project/selectProject/${id}`).then((res) => res);
};

// 项目管理删除
export const projectDel = (id) => {
  return del(`/project/delete/${id}`).then((res) => res);
};

// 项目管理同步
export const projectSync = () => {
  return post('/vou/project/sync').then((res) => res);
};

// 模板删除
export const voucherTmplDel = (id) => {
  return del(`/voucher/tmpl/voucherTmplDel?id=${id}`).then((res) => res);
};

// 模板编辑
export const getTmplAndItemById = (id) => {
  return post(`/voucher/tmpl/getTmplAndItemById`, id).then((res) => res);
};

// 同步科目
export const syncAccounts = () => {
  return post('/voucher/accounts/syncAccounts').then((res) => res);
};

// 生成凭证
export const voucherBuild = (data) => {
  return post('/voucher/build', data).then((res) => res);
};

// 关联项目
export const projectName = (data) => {
  return post('/invoice/project', data).then((res) => res);
};
