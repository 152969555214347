<template>
  <div class="query">
    <el-form :model="value" inline label-width="100px">
      <template v-for="(item, index) in showQueryItem">
        <!-- Input输入框 -->
        <template v-if="['text', 'textarea', 'number', 'email'].indexOf(item.type) !== -1">
          <el-form-item :key="index" :label="item.label" :prop="item.prop ? item.prop : ''">
            <el-input :type="item.type" v-model="value[item.name]" :placeholder="item.placeholder" @keyup.enter.native="handleText(item.type)" />
          </el-form-item>
        </template>
        <!-- Select选择框 -->
        <template v-if="item.type === 'select'">
          <el-form-item :key="index" :label="item.label" :prop="item.prop ? item.prop : ''">
            <el-select v-model="value[item.name]" :placeholder="item.placeholder">
              <el-option v-for="(option, key) in soptions(item.options)" :key="key" :label="option.label" :value="option.value" />
            </el-select>
          </el-form-item>
        </template>
        <!-- Date选择框 -->
        <template v-if="['month', 'date', 'datetimerange', 'daterange', 'datetime'].indexOf(item.type) !== -1">
          <el-form-item :key="index" :label="item.label" :prop="item.prop ? item.prop : ''">
            <el-date-picker v-model="value[item.name]" :type="item.type" range-separator="" start-placeholder="开始日期" end-placeholder="结束日期" :value-format="item.format" :picker-options="option" @change="handleDateLimit" :placeholder="item.placeholder" />
          </el-form-item>
        </template>
        <!-- Switch开关框 -->
        <template v-if="item.type == 'switch'">
          <el-form-item :key="index" :label="item.label" :prop="item.prop ? item.prop : ''">
            <el-switch v-model="value[item.name]" />
          </el-form-item>
        </template>
        <!-- Radio单选框 -->
        <template v-if="item.type == 'radio'">
          <el-form-item :key="index" :label="item.label">
            <el-radio-group v-model="value[item.name]">
              <el-radio v-for="(option, key) in item.options" :key="key" :label="option" />
            </el-radio-group>
          </el-form-item>
        </template>
        <!-- Checkbox多选框 -->
        <template v-if="item.type === 'checkbox'">
          <el-checkbox-group :key="index" v-model="value[item.name]">
            <el-checkbox v-for="checkbox in item.checkboxs" :label="checkbox.value" :key="checkbox.value">
              {{ checkbox.label }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </template>
      <el-form-item>
        <div style="display: flex">
          <div class="text" v-if="isDisplay">
            <div class="btn" v-if="isHide" @click="handleQueryIsHide">展开<i class="el-icon-arrow-down"></i></div>
            <div class="btn" v-else @click="handleQueryIsHide">收起<i class="el-icon-arrow-up"></i></div>
          </div>
          <div class="operate">
            <div class="button">
              <el-button type="default" @click="handleQueryReset">重置</el-button>
              <el-button type="primary" @click="handleQuery">查询</el-button>
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isHide: true,
      option: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now();
        }
      }
    };
  },
  computed: {
    isDisplay() {
      return this.items.length > 1;
    },
    showQueryItem() {
      if (this.isHide) {
        return this.items.slice(0, 3);
      }
      return this.items;
    },
    soptions() {
      return (value) => {
        if (value.length == 0) return this.options;
        return value;
      };
    }
  },
  methods: {
    /* 查询展开/收起 */
    handleQueryIsHide() {
      this.isHide = !this.isHide;
    },
    /* 重置查询操作 */
    handleQueryReset() {
      this.value = {};
      this.$emit('resetForm');
    },
    /* 查询操作 */
    handleQuery() {
      this.$emit('query', this.value);
    },
    /* 日期限制 */
    handleDateLimit() {
      let start = (new Date(this.date) * 1000) / 1000;
      if (start >= Date.now()) {
        this.date = new Date();
      }
    },
    /* 处理text框的按键事件 */
    handleText(type) {
      if (type == 'text') {
        this.handleQuery();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.query {
  display: flex;
  padding: 24px 24px 0 24px;

  .el-form {
    display: flex;
    flex-wrap: wrap;
    // width: 80%;

    .el-form-item {
      margin-right: 24px;
      margin-bottom: 24px;
    }

    ::v-deep .el-form-item__label {
      text-align: right;
      display: inline-block;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // max-width: 6em;
    }
  }

  .operate {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 20px;
  }
  .text {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    .btn {
      color: #1890ff;
      cursor: pointer;
    }
  }
}

::v-deep .el-date-editor .el-range-input {
  width: 83%;
}
</style>
